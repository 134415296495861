.page-not-found--wrapper {
  padding: 40px;
  min-height: calc(100vh - 159px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    margin-bottom: 0;
  }
}
