@import "../../../styles/base";

.oa-otkup-zlata {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1400px;
    margin: 4rem auto 3rem auto;
    padding: 0rem 1rem;
    grid-gap: 3vw;
    .oa-otkup {
        padding: 0rem;
        margin: 0rem;
        display: flex;
        flex-direction: column;
    }
}

.oa-otkup-zlata-table {
    display: grid;
    grid-template-columns: 1fr 25%;
    padding: 0rem;
    margin: 0rem;
    li {
        list-style: none;
        border: 1px solid #bfae90;
        padding: 10px;
        &:nth-child(1),
        &:nth-child(2) {
            background-color: #655637;
            color: var(--color-white);
            padding: 14px 10px;
        }
    }
}

.grey-field {
    background: rgba(236, 238, 239, 0.29);
    padding: 1rem;
    font-size: 17px;
    line-height: 24px;
    margin-top: auto;
    p {
        margin: 0px;
    }
}

@include mediaMaxWidth(767px) {
    .oa-otkup-zlata {
        grid-template-columns: 1fr;
        max-width: 540px;
        grid-gap: 2rem;
        .oa-otkup {
            > p + p {
                margin-top: 1rem;
            }
        }
    }
    .grey-field {
        margin: 0px -1rem;
    }
    .oa-otkup-zlata-table {
        grid-template-columns: 1fr 32%;
    }
}
