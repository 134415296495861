@import "styles/base";

.oa-dashboard-main-section {
  display: grid;
  grid-column-gap: 1rem;
  background-image: url("../../images/pocetna/pozadina-min.jpg");
  background-repeat: no-repeat;
  position: relative;
  background-position: top center;
  > img {
    position: relative;
    width: 100%;
    bottom: -5px;
    left: 29%;
  }
  .oa-dashboard-main-section--item {
    align-self: center;
    z-index: 2;
    h1 {
      color: #bfae90;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: calc(2vw + 1rem);
      margin-top: 0px;
      margin-bottom: 1rem;
    }
    p {
      margin-top: 0px;
      font-size: calc(1vw + 0.5rem);
      line-height: calc(1vw + 1rem);
    }
    a {
      color: var(--color-white);
    }
  }
}

.oa-dashboard-box-section {
  display: grid;
  grid-template-columns: 1fr;
  .oa-dashboard-box-section--item {
    position: relative;
    &:hover {
      .oa-dashboard-box-section--item-overlay {
        height: 100%;
        opacity: 1;
      }
    }
    img {
      cursor: pointer;
      width: 100%;
    }
    .oa-dashboard-box-section--item-overlay {
      position: absolute;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 100%;
      height: 0;
      transition: 0.5s ease;
      top: 0;
      background-color: rgba(0, 0, 0, 0.15);
      cursor: pointer;
      opacity: 0;
      p {
        white-space: nowrap;
        color: var(--color-white);
        font-size: calc(1vw + 0.6rem);
        position: absolute;
        overflow: hidden;
        top: 88%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        margin: 0px;
      }
    }
  }
}

@include mediaMinWidth(576px) {
  .oa-dashboard-box-section {
    grid-template-columns: 1fr 1fr;
  }
}

@include mediaMinWidth(768px) {
  .oa-dashboard-box-section {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .oa-dashboard-main-section {
    grid-template-columns: 50% 40%;
  }
}

@include mediaMaxWidth(767px) {
  .oa-dashboard-main-section {
    > img {
      margin: 0 auto;
      width: 65%;
      left: 0px;
    }
    .oa-dashboard-main-section--item {
      position: relative;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.5);
      padding: 1rem;
      h1 {
        font-size: 27px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .oa-dashboard-box-section {
    .oa-dashboard-box-section--item {
      position: relative;
      &:hover {
        .oa-dashboard-box-section--item-overlay {
          height: auto;
        }
      }
      .oa-dashboard-box-section--item-overlay {
        opacity: 1;
        overflow: visible;
        bottom: 45px;
        top: auto;
        background-color: transparent;
        p {
          font-size: 28px;
        }
      }
    }
  }
}
