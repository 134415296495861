@import "../../styles/base";

$red-color: red;

.oa-contact-title {
  color: #bfae90;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 4rem;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 34px;
  padding: 0rem 1rem;
}

.oa-contact-boxes {
  max-width: 540px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 0rem 1rem;
  justify-items: center;
  text-align: center;
  grid-gap: 2rem;
  img {
    width: 55px;
    height: 55px;
  }
  .oa-contact-boxes--item {
    > h3 {
      color: var(--color-white);
      padding-top: 8px;
      font-size: 30px;
      margin: 1.2rem 0rem 0rem;
    }
    > p {
      a {
        color: var(--color-white);
        &:hover {
          opacity: 0.7;
        }
      }
    }
    > p + p {
      margin-top: -8px;
    }
    .oa-contact-boxes--item-icon {
      box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.08);
      width: 115px;
      height: 115px;
      text-align: center;
      position: relative;
      overflow: hidden;
      margin: 0 auto 15px;
      border-width: 8px;
      border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: #655637;
      &:hover {
        background-color: #a58c5a;
      }
      &::before {
        border-color: #e2e2e2;
      }
      a:hover {
        cursor: pointer;
      }
    }
  }
}

.oa-contact-form {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 540px;
  margin: 4rem auto;
  padding: 0rem 1rem;
  grid-gap: 3rem;
  &-title {
    text-align: center;
    color: #bfae90;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 0px;
    margin-bottom: 2rem;
    font-size: 28px;
  }
  .oa-contact-form--aside {
    &-title {
      text-align: center;
      color: #bfae90;
      font-weight: 400;
      letter-spacing: 1px;
      margin-top: 0px;
      margin-bottom: 2rem;
      font-size: 28px;
    }
    &-social {
      display: grid;
      text-align: center;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: center;
      max-width: 500px;
      margin: 0 auto;
      a {
        color: var(--color-white);
        text-decoration: none;
        h3 {
          margin-bottom: 0rem;
        }
      }
    }
  }
}

@include mediaMinWidth(768px) {
  .oa-contact-boxes {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1140px;
  }
  .oa-contact-form {
    grid-template-columns: 1fr;
    max-width: 1400px;
    grid-gap: 3.5vw;
  }
}

.red-star {
  color: $red-color;
  font-size: 22px;
  position: absolute;
  padding-left: 5px;
}

.instagram-icon-hov {
  width: 90px;
  height: 90px;
}

.facebook-icon-hov {
  width: 87px;
  height: 87px;
}

.facebook-icon-hov,
.instagram-icon-hov {
  &:hover {
    opacity: 0.8;
  }
}

input.error,
select.error,
textarea.error {
  border-color: $red-color;
  border-width: 1px;
  border-style: solid;
  &:valid {
    border-style: auto;
    border-color: inherit;
  }
}

.has-danger {
  .go-up,
  .form-control-feedback {
    color: $red-color;
    padding-top: 3px;
  }
  .form-control-danger,
  .form-control:focus {
    border: 1px solid #c53531 !important;
  }
}

.go-up {
  top: -12px !important;
  font-size: 0.75rem;
  padding-left: 5px;
  padding-right: 5px;
  left: 9px !important;
}
