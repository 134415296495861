@import "variables";

.radio + .radio,
.checkbox + .checkbox {
    margin-top: 6px;
}

.radio,
.checkbox {
    min-height: 18px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-left: 0px;
    position: relative;
    line-height: 20px;
    width: fit-content;
}

.radio > label,
.checkbox > label {
    padding-left: 30px;
    cursor: pointer;
    margin: 0px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.radio > input[type="radio"]:first-child:not(:checked) + label::before,
.checkbox > input[type="checkbox"]:first-child:not(:checked) + label::before,
.radio > input[type="radio"]:first-child:not(:checked) + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:first-child:not(:checked) + input[type="hidden"] + label::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #bfae90;
    border-radius: 2px;
    background-color: #222;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.radio > input[type="radio"]:first-child:not(:checked) + label::before,
.radio > input[type="radio"]:first-child:not(:checked) + input[type="hidden"] + label::before {
    border-radius: 50%;
}

.radio > input[type="radio"]:first-child:checked + label::before,
.radio > input[type="radio"]:first-child:checked + input[type="hidden"] + label::before {
    content: "";
    background: url("../images/radio-button-blue.svg") no-repeat;
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 21px;
    border-radius: 2px;
    border: 1px solid #bfae90;
    background-color: #222;
    margin-top: -1px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-position: center;
}

.checkbox > input[type="checkbox"]:first-child:checked + label::before,
.checkbox > input[type="checkbox"]:first-child:checked + input[type="hidden"] + label::before {
    content: "";
    display: inline-block;
    background: url("../images/checkmark-small-blue.svg") no-repeat;
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 16px;
    border-radius: 2px;
    border: 1px solid #bfae90;
    background-color: #222 !important;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.radio > input[type="radio"]:first-child:checked + label::before,
.radio > input[type="radio"]:first-child:checked + input[type="hidden"] + label::before {
    border-radius: 50%;
}

.radio > input[type="radio"]:first-child:not(:checked):not(:disabled):hover + label::before,
.checkbox > input[type="checkbox"]:first-child:not(:checked):not(:disabled):hover + label::before,
.radio > input[type="radio"]:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.checkbox
    > input[type="checkbox"]:first-child:not(:checked):not(:disabled):hover
    + input[type="hidden"]
    + label::before {
    border: 1px solid #bfae90;
    opacity: 0.8;
}

.radio > input[type="radio"]:first-child:disabled + label,
.radio > input[type="radio"]:first-child:disabled + label::before,
.checkbox > input[type="checkbox"]:first-child:disabled + label,
.checkbox > input[type="checkbox"]:first-child:disabled + label::before,
.radio > input[type="radio"]:first-child:disabled + input[type="hidden"] + label,
.radio > input[type="radio"]:first-child:disabled + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:first-child:disabled + input[type="hidden"] + label,
.checkbox > input[type="checkbox"]:first-child:disabled + input[type="hidden"] + label::before {
    pointer-events: none;
    cursor: default;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.radio > input[type="radio"]:first-child:disabled + label,
.checkbox > input[type="checkbox"]:first-child:disabled + label,
.radio > input[type="radio"]:first-child:disabled + input[type="hidden"] + label,
.checkbox > input[type="checkbox"]:first-child:disabled + input[type="hidden"] + label {
    filter: alpha(opacity=65);
    opacity: 0.65;
}

.radio > input[type="radio"]:first-child,
.checkbox > input[type="checkbox"]:first-child {
    opacity: 0;
    margin-left: 0px;
    z-index: 2;
    cursor: pointer;
}

.radio > input[type="radio"]:first-child:disabled,
.checkbox > input[type="checkbox"]:first-child:disabled {
    opacity: 0;
    cursor: default;
}

/* not checked hover style*/
.icheck-primary > input[type="radio"]:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-primary > input[type="checkbox"]:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-primary
    > input[type="radio"]:first-child:not(:checked):not(:disabled):hover
    + input[type="hidden"]
    + label::before,
.icheck-primary
    > input[type="checkbox"]:first-child:not(:checked):not(:disabled):hover
    + input[type="hidden"]
    + label::before {
    border-color: #818a91;
}

/*checked style*/
.icheck-primary > input[type="radio"]:first-child:checked + label::before,
.icheck-primary > input[type="checkbox"]:first-child:checked + label::before,
.icheck-primary > input[type="radio"]:first-child:checked + input[type="hidden"] + label::before,
.icheck-primary > input[type="checkbox"]:first-child:checked + input[type="hidden"] + label::before {
    background-color: var(--color-white);
}
