@import "styles/base";

.oa-header {
  border-bottom: 1px solid #9e9e9e;
  height: 75px;
  background: transparent;
  transition: 0.2s;
  .oa-header--nav {
    max-width: 1400px;
    margin: 0rem auto;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    .oa-header--nav-hamburger {
      display: none;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      border: 1px solid #bfae90;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 24px;
        background: #bfae90;
        border-radius: 9px;
        opacity: 1;
        left: 7px;
        &:first-child {
          top: 10px;
        }
        &:nth-child(2) {
          top: 18px;
        }
        &:nth-child(3) {
          top: 26px;
        }
      }
    }
    .oa-header--nav-languages {
      margin-left: auto;
      button {
        background: transparent;
        padding: 0px;
        border: none;
        cursor: pointer;
      }
      button + button {
        margin-left: 1rem;
      }
    }
  }

  ul {
    display: flex;
    padding: 0px;
    margin: 0px;
  }
  li {
    list-style: none;
    a {
      color: var(--color-white);
      text-transform: uppercase;
      font-weight: 500;
      text-decoration: none;
      &:hover {
        color: #bfae90;
      }
    }
    .active {
      color: #bfae90;
    }
  }
  li + li {
    margin-left: 2rem;
  }
}

.active {
  position: relative;
  .line {
    display: block;
    position: absolute;
    bottom: -27px;
    height: 4px;
    width: 100%;
    z-index: 1;
    background: #bfae90;
  }
}

@include mediaMaxWidth(767px) {
  .oa-header {
    height: initial;
    min-height: 75px;
    .oa-header--nav {
      max-width: 540px;
      position: relative;
      .oa-header--nav-hamburger {
        display: block;
      }
      .oa-header--nav-languages {
        position: absolute;
        top: 1.5rem;
        left: 1rem;
      }
    }
    ul {
      text-align: center;
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 2rem;
      flex-direction: column;
      li + li {
        margin-left: 0rem;
        margin-top: 1.3rem;
      }
    }
    .visible {
      display: block;
    }
    .hidden {
      display: none;
    }
  }
  .active {
    .line {
      display: none;
    }
  }
}
