@import "base";
@import "checkbox_radio_buttons";

:root {
  --color-white: #ffffff;
  --color-black: #000000;
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0px;
  height: 100%;
  font-family: $font-family-base;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #272727;
  color: var(--color-white);
}

.ant-input {
  background: transparent !important;
  color: #ddd !important;
}

.oa-fieldset {
  border: none;
  position: relative;
  padding: 0px;
  margin-bottom: 1.2rem;
  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #575757;
    color: #ddd;
    font-weight: 400;
    border-radius: 0px;
    min-height: 44px;
  }
  textarea {
    font-size: 1rem;
  }
}

input,
textarea,
button {
  font-family: $font-family-base;
}

.oa-button {
  width: 135px;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background: #a0a3a7;
  }
}

textarea {
  min-height: 137px;
  resize: none;
  width: 100%;
  padding: 0.75rem;
}

::-webkit-input-placeholder {
  color: #eceeef4f;
}

::-moz-placeholder {
  color: #eceeef4f;
}

:-ms-input-placeholder {
  color: #eceeef4f;
}

:-moz-placeholder {
  color: #eceeef4f;
}

a:focus,
a:hover {
  color: var(--color-white);
  text-decoration: none;
}

.form-control {
  background-color: transparent;
  border-color: #575757;
  color: #ddd;
  font-weight: normal;
  &:focus {
    color: var(--color-white);
    background-color: transparent;
    border-color: var(--color-white);
  }
}

.oa-alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  position: relative;
  padding: 1.5rem 2rem;
  border-radius: 0.25rem;
}

.oa-alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  position: relative;
  padding: 1.5rem 2rem;
  border-radius: 0.25rem;
}

.oa-custom-label {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 13px;
  transition: 0.2s ease all;
  background: #272727;
  color: #9e9e9e;
}

.oa-success-error-message-wrapper:not(:empty) {
  margin-top: 3rem;
}

@include mediaMaxWidth(575px) {
  .oa-button {
    width: 100%;
  }
  .oa-alert-danger,
  .oa-alert-success {
    padding: 1.5rem 1rem;
  }
}
