@import "../../styles/base";

.oa-otkupljujemo {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 2000px;
  margin: 0 auto;
  .oa-otkupljujemo--item {
    text-align: center;
    position: relative;

    > picture {
      > img {
        width: 100%;
        height: 100%;
      }

    }
    .oa-otkupljujemo--item-desc {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      padding: 20px 15px 2vw;
      box-sizing: border-box;
      transition: all 0.25s ease;
      h2 {
        transition: all 0.3s ease-in-out;
        color: var(--color-white);
        font-size: calc(1vw + 0.6rem);
        margin: 0;
        position: relative;
        bottom: 0;
        line-height: 22px;
        text-transform: uppercase;
      }
      span {
        height: 1px;
        width: 0;
        margin: 0 auto;
        background: var(--color-white);
        overflow: hidden;
        display: block;
        transition: all 0.25s ease;
      }
    }
    &:hover {
      .oa-otkupljujemo--item-desc {
        h2 {
          bottom: 30px;
        }
        span {
          width: 60%;
        }
      }
      img {
        filter: blur(3px);
      }
    }
  }
}

.oa-otkup {
  max-width: 1400px;
  margin: 4rem auto 3rem auto;
  padding: 0rem 1rem;
  > p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    span {
      font-size: 45px;
      font-weight: 100;
      color: #bfae90;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
  > p + p {
    margin-top: 2rem;
  }
}

.oa-otkup-grid-images {
  display: grid;
  grid-template-columns: 1fr;
}

@include mediaMinWidth(576px) {
  .oa-otkupljujemo,
  .oa-otkup-grid-images {
    grid-template-columns: 1fr 1fr;
  }
}

@include mediaMinWidth(768px) {
  .oa-otkupljujemo,
  .oa-otkup-grid-images {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@include mediaMaxWidth(767px) {
  .oa-otkupljujemo {
    .oa-otkupljujemo--item {
      .oa-otkupljujemo--item-desc {
        padding: 25px 15px 25px;
        background: rgba(0, 0, 0, 0.5);
        transition: none;
        h2 {
          font-size: 25px;
        }
        span {
          display: none;
        }
      }
      &:hover {
        .oa-otkupljujemo--item-desc {
          h2 {
            bottom: 30px;
          }
          span {
            width: 60%;
          }
        }
        img {
          filter: none;
        }
      }
    }
  }
  .oa-otkup {
    > p {
      font-size: 17px;
      span {
        font-size: 30px;
        margin-right: 0.5rem;
      }
    }
  }
}
