$black-gradient: #171717;

.oa-footer {
  text-align: center;
  padding: 2rem 1rem;
  color: var(--color-white);
  border-top: 1px solid #9e9e9e;
  margin-top: auto;
}

.oa-footer--owner {
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  &:hover {
    text-decoration: underline;
    color: #8a6d3b;
    .oa-footer--owner-box {
      display: block;
    }
  }
}

.oa-footer--owner-box {
  width: 235px;
  height: 335px;
  position: absolute;
  right: 0px;
  bottom: 30px;
  display: none;
  z-index: 1001;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);

  .oa-footer--owner-box-top {
    $blue-gradient: #009be3;
    cursor: default;
    height: 84px;
    background-color: $blue-gradient;
    background-image: linear-gradient($blue-gradient, #0088e3);
  }
  .oa-footer--owner-box-circle {
    cursor: default;
    width: 125px;
    height: 125px;
    position: absolute;
    background: var(--color-white);
    border-radius: 50%;
    right: 57px;
    top: 15px;
    border: 7px solid $black-gradient;
    img {
      position: absolute;
      right: -55.5px;
      top: -19px;
      width: 222px;
      height: auto;
    }
  }
  .oa-footer--owner-box-bottom {
    cursor: default;
    height: 250px;
    background-color: $black-gradient;
    background-image: linear-gradient($black-gradient, #1c1c1c);
    &-name {
      text-align: center;
      font-size: 23px;
      padding-top: 58px;
      color: var(--color-white);
      margin-bottom: 15px;
      margin-top: 0px;
    }
    &-job {
      text-align: center;
      color: var(--color-white);
      padding-left: 20px;
      padding-right: 20px;
      line-height: 18px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $black-gradient;
      bottom: -7px;
      right: 50px;
      z-index: 5;
      margin-left: -15px;
    }
    .linkedIn {
      position: absolute;
      width: 91px;
      left: 16px;
      bottom: 38px;
    }
    .facebook {
      width: 94px;
      position: absolute;
      bottom: 40px;
      right: 15px;
    }
    .facebook,
    .linkedIn {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .oa-footer--owner-box-hidden {
    height: 30px;
  }
}
