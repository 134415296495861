@import "../../styles/base";

.oa-procena-otkup {
  max-width: 1400px;
  padding: 0rem 1rem;
  margin: 4rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.5vw;
}

.oa-procena-otkup--aside {
  h1 {
    color: #bfae90;
    letter-spacing: 1px;
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 0px;
  }
  ul {
    padding: 0px;
    margin-bottom: 0px;
    .oa-procena-otkup--aside-item {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 1rem;
      span {
        font-size: 25px;
      }
      p {
        margin: 0px;
        line-height: 20px;
      }
    }
    .oa-procena-otkup--aside-item + .oa-procena-otkup--aside-item {
      margin-top: 1.2rem;
    }
  }
}

.oa-procena-otkup--form {
  .oa-procena-otkup--form-radio {
    margin-bottom: 1rem;
    > p {
      border-bottom: 1px solid #575757;
      padding-bottom: 8px;
      margin: 0px 0px 0.8rem 0px;
    }
  }
  .oa-procena-otkup--form-grid {
    display: grid;
    grid-column-gap: 1rem;
  }
}

@include mediaMaxWidth(767px) {
  .oa-procena-otkup {
    max-width: 540px;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
}

@include mediaMinWidth(576px) {
  .oa-procena-otkup--form-grid {
    &.two {
      grid-template-columns: 1fr 1fr;
    }
  }
}
